<template>
<div>
    <h1>Page Not Found</h1>
    <p>The page you're looking for doesn't exist.</p>
    <router-link to="/">Go Back Home</router-link>
</div>
</template>

  
  
<script>
export default {
    name: 'NotFound',
};
</script>
  
  
<style scoped>
h1 {
    color: red;
    text-align: center;
}

p {
    text-align: center;
}
</style>
