import { createRouter, createWebHashHistory } from 'vue-router';
import MainLayout from '../MainLayout.vue';
import ProjectPage from '../Vue/pages/ProjectPage.vue';
import NotFound from '../Vue/pages/NotFound.vue';
import PdfPage from '../Vue/pages/PdfPage.vue';  // Add PdfPage import

const routes = [
    {
        path: '/',
        name: 'Home',
        component: MainLayout,
    },
    {
        path: '/projects/:title',
        name: 'Project',
        component: ProjectPage,
        props: true,
    },
    {
        path: '/pdf',
        name: 'PdfPage',
        component: PdfPage
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            };
        } else {
            return { top: 0 };
        }
    },
});

export default router;
