<template>
<div id="print-section" class="portfolio-a4">
    <!-- Page 1 - Cover Page, About & Education, Languages -->
    <section class="cover-page">
        <div class="profile-pic-section">
            <img class="profile-pic" src="../../assets/images/profile-pic.png" alt="profile picture" />
        </div>
        <h1>{{ pdfData.main.name.first }} {{ pdfData.main.name.last }}</h1>
        <h2 v-for="title in pdfData.main.titles" :key="title" class="title">{{ title }}</h2>
        <div class="contact-details">
            <p>
                <a :href="pdfData.contact.portfolio" target="_blank" class="portfolio-link">
                    {{ pdfData.contact.portfolio.replace('https://', '').replace('http://', '') }}
                </a>
            </p>
            <p><strong>Location:</strong> {{ pdfData.contact.city }}</p>
            <p><strong>Email:</strong> {{ pdfData.contact.email }}</p>
            <p><strong>Phone:</strong> {{ pdfData.contact.phone }}</p>
            <div class="social-links">
                <a v-for="social in pdfData.contact.social" :key="social.name" :href="social.url" target="_blank">
                    <i :class="social.faClass" class="social-icon"></i>
                </a>
            </div>
        </div>

    </section>

    <section class="about-education">
        <div class="about-section">
            <h3 class="section-title">{{ pdfData.main.headings.about }}</h3>
            <p>{{ pdfData.about.bio }}</p>
        </div>
        <div class="education-section">
            <h3 class="section-title">Education</h3>
            <ul style="list-style-type: none; padding: 0;">
                <li v-for="fact in pdfData.about.facts" :key="fact.name" class="education-item">
                    <strong>{{ fact.name }}:</strong> {{ fact.value }}
                </li>
            </ul>
        </div>
    </section>

    <section class="languages-page break-after">
        <h3 class="section-title">Languages</h3>
        <ul class="languages-list">
            <li v-for="language in pdfData.main.languages" :key="language.name" class="language-item avoid-break">
                <strong>{{ language.name }}:</strong> {{ language.level }}
            </li>
        </ul>
    </section>

    <!-- Page 2 - Skills -->
    <section class="skills-page">
        <h3 class="section-title">{{ pdfData.main.headings.skills }}</h3>
        <div class="skills-category avoid-break" v-for="category in pdfData.skills.categories" :key="category.category">
            <h4 class="category-title">{{ category.category }}</h4>
            <div class="skills-items-container">
                <ul>
                    <li v-for="skill in category.skillList" :key="skill.name" class="skill-item">
                        <span>{{ skill.name }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </section>

    <!-- Page 3 - Work Experience -->
    <section class="work-experience-page break-after">
        <h3 class="section-title">Work Experience</h3>
        <div class="work-entry avoid-break" v-for="experience in pdfData.main.workExperience" :key="experience.title">
            <h4 class="job-title">{{ experience.title }} - {{ experience.company }}</h4>
            <p class="job-duration">{{ experience.startDate }} - {{ experience.endDate }}</p>
            <p class="job-description">{{ experience.description }}</p>
        </div>
    </section>

    <!-- Page 4 - Portfolio Projects -->
    <section class="portfolio-projects">
        <h3 class="section-title">{{ pdfData.main.headings.portfolio }}</h3>
        <div class="project-entry avoid-break" v-for="project in pdfData.portfolio.projects" :key="project.title">
            <a :href="project.link" target="_blank" class="project-title-link">
                <h4 class="project-title">{{ project.title }} <i class="fas fa-link"></i></h4>
            </a>
            <p v-html="project.description" class="project-description"></p>
        </div>
    </section>
</div>
</template>

<script>
import pdfData from '../../data/data.json';

export default {
    data() {
        return {
            pdfData: pdfData
        };
    }
};
</script>

<style>
@media print {

    /* Hide everything except the specific div */
    body * {
        visibility: hidden;
    }

    #print-section,
    #print-section * {
        visibility: visible;
    }

    /* Ensure the print section takes up the full width and centers horizontally */
    #print-section {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        padding: 0;
        margin: 0;
        width: 100%;
        box-shadow: none;
        /* Remove box shadow */
        border: none;
    }

    @page {
        size: A4;
        margin: 20mm 0;
    }

    body {
        -webkit-print-color-adjust: exact;
        margin: 0;
        padding: 0;
    }

    .break-after {
        page-break-after: always;
    }

    .avoid-break {
        page-break-inside: avoid;
    }
}

/* Adjustments to avoid breaking inside sections */
.work-entry,
.project-entry,
.language-item {
    page-break-inside: avoid;
}

.portfolio-a4 {
    padding: 20mm;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #ffffff;
    max-width: 210mm;
    min-height: 297mm;
    margin: 0 auto;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #333;
}

.cover-page {
    text-align: center;
    margin-bottom: 40px;
}

.profile-pic-section {
    margin-bottom: 20px;
}

.profile-pic {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 2px solid #007bff;
}

.title {
    font-size: 20px;
    color: #007bff;
    margin: 5px 0;
}

.contact-details {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.5;
}

.social-links {
    margin-top: 10px;
}

.social-icon {
    font-size: 18px;
    color: #007bff;
    margin: 0 5px;
}

.section-title {
    font-size: 18px;
    color: #007bff;
    margin-bottom: 15px;
    border-bottom: 1px solid #007bff;
    padding-bottom: 5px;
}

.about-education,
.skills-page,
.work-experience-page,
.portfolio-projects,
.languages-page {
    margin-bottom: 40px;
    padding: 0 10mm;
}

.education-item {
    margin-bottom: 10px;
    font-size: 14px;
}

.skills-category {
    margin-bottom: 20px;
}

.skills-items-container {
    padding-left: 15px;
    /* border-left: 3px solid #007bff; */
    /* background-color: #f9f9f9; */
    padding-top: 10px;
    padding-bottom: 10px;
    list-style-type: none;
}

.category-title {
    font-size: 16px;
    margin-bottom: 10px;
    color: #333;
    text-transform: uppercase;
}

.skill-item {
    margin-bottom: 8px;
    font-size: 14px;
    padding-left: 0;
    position: relative;
    list-style-type: none;
}

.work-entry {
    margin-bottom: 30px;
}

.job-title {
    font-size: 16px;
    color: #007bff;
    margin-bottom: 5px;
}

.job-duration {
    font-size: 14px;
    color: #666;
}

.job-description {
    font-size: 14px;
    color: #333;
    line-height: 1.5;
}

.project-entry {
    margin-bottom: 25px;
}

.project-title {
    font-size: 16px;
    color: #007bff;
}

.project-description {
    font-size: 14px;
    color: #333;
    line-height: 1.5;
}

.project-link {
    display: inline-block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.project-link:hover {
    text-decoration: underline;
}

.languages-list {
    list-style-type: none;
    padding: 0;
}

.language-item {
    margin-bottom: 10px;
    font-size: 14px;
    padding-left: 10px;
    position: relative;
}

#app {
    background-color: transparent;
}

.portfolio-link {
    color: #007bff;
    font-weight: bold;
    text-decoration: none;
    padding-bottom: 2px;
    transition: all 0.2s ease-in-out;
}

.portfolio-link:hover {
    color: #0056b3;
    border-bottom-color: #0056b3;
    text-decoration: underline;
}
</style>
