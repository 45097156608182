<template>
<router-view />
</template>

<script>
export default {
    name: "App",
};
</script>

<style>
@import "./styles/css/main.css";
</style>
