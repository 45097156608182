<template>
  <div :style="backgroundStyle" class="background-container">
    <canvas ref="waveCanvas" class="wave-canvas"></canvas>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    useComplexBackground: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      elapsed: 0,
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundColor: 'black', // Static black background
      };
    },
  },
  mounted() {
    if (this.useComplexBackground) {
      this.startAnimation();
      this.drawWaves(); // Draw the waves when the component is mounted
    }
  },
  methods: {
    startAnimation() {
      setInterval(() => {
        this.elapsed = Date.now(); // Update elapsed time
        this.drawWaves(); // Re-draw the waves with updated time
      }, 50); // Update every 50ms for smoother animation
    },
    drawWaves() {
      const canvas = this.$refs.waveCanvas;
      const ctx = canvas.getContext('2d');

      // Set canvas dimensions to match the container
      canvas.width = this.$el.clientWidth;
      canvas.height = this.$el.clientHeight;

      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Define wave parameters
      const waveCount = 5; // Number of sine waves to sum
      const amplitude = 50; // Base amplitude
      const baseFrequency = 0.01; // Base frequency
      const thickness = 2; // Wave thickness
      const phaseShiftSpeed = 0.0005; // Speed at which the phase shifts over time

      // Set wave style
      ctx.strokeStyle = 'white';
      ctx.lineWidth = thickness;
      ctx.globalAlpha = 0.6; // Slight transparency

      // Function to calculate the y position for the sum of sines
      const sumOfSines = (x, time) => {
        let y = 0;
        for (let i = 0; i < waveCount; i++) {
          const frequency = baseFrequency + i * 0.002; // Slightly different frequency for each wave
          const phaseShift = time * phaseShiftSpeed * i;
          y += amplitude * Math.sin(frequency * x + phaseShift);
        }
        return y;
      };

      // Begin drawing the sum of sine waves
      ctx.beginPath();
      ctx.moveTo(0, canvas.height / 2 + sumOfSines(0, this.elapsed));

      for (let x = 0; x < canvas.width; x++) {
        const y = canvas.height / 2 + sumOfSines(x, this.elapsed);
        ctx.lineTo(x, y);
      }

      ctx.stroke();
    },
  },
};
</script>

<style scoped>
.background-container {
  width: 100%;
  height: 100%;
  min-height: 300px;
  position: relative;
  overflow: hidden;
}

.wave-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
