<template>
<footer class="footer section-dark font-small text-center">
    <ul class="list-inline">
        <li class="list-inline-item">
            <router-link class="nav-item nav-link" :to="'/#home'">Home</router-link>
        </li>
        <li class="list-inline-item">
            <router-link class="nav-item nav-link" :to="'/#about'">About</router-link>
        </li>
        <li class="list-inline-item">
            <router-link class="nav-item nav-link" :to="'/#skills'">Skills</router-link>
        </li>
        <li class="list-inline-item">
            <router-link class="nav-item nav-link" :to="'/#portfolio'">Portfolio</router-link>
        </li>
        <li class="list-inline-item">
            <router-link class="nav-item nav-link" :to="'/#contact'">Contact</router-link>
        </li>
    </ul>

    <ul class="list-inline">
        <li v-for="(item, i) in social" :key="i" class="social-item list-inline-item">
            <a :href="item.url" :alt="item.name">
                <i :class="item.faClass"> </i>
            </a>
        </li>
    </ul>

    <div class="copyright">
        <p>
            Copyright © {{ new Date().getFullYear() }}
            {{ text.main.name.first }} {{ text.main.name.last  }}.
            {{ text.footer.copyrightText }}
        </p>
    </div>
    <div class="credit">
        <p>
            Designed with 👽 by
            <a id="username" href="https://www.github.com/darnocer" alt="Darian's Github">darnocer</a>
            &
            <a id="username" href="https://www.github.com/imtambovtcev" alt="Ivan's Github">ivanchai</a>
        </p>
    </div>
</footer>
</template>

<script>
import data from "../../data/data.json";

export default {
    name: "Footer",
    data() {
        return {
            text: data,
            social: data.contact.social,
        };
    },
};
</script>

<style lang="scss"></style>
