<template>
<section id="portfolio" class="dark-section">
    <div class="container-fluid">
        <h1 class="section-header">{{ heading }}</h1>

        <!-- start of filters  -->
        <div class="row filters">
            <ul class="list-inline mx-auto">
                <li v-for="item in filters" :key="item.title" class="list-inline-item filter">
                    <a class="nav-item" :class="item.filter === currentFilter ? 'active' : null" :data-filter="item.filter" @click="setFilter">{{ item.filter }}</a>
                </li>
            </ul>
        </div>
        <!-- end of filters  -->

        <div id="portfolio-container" class="row">
            <div data-aos="fade-right" data-aos-duration="1000" v-for="(item, index) in filteredProjects" :key="index" class="col-sm-12 col-md-3 flex-col">
                <div class="portfolio-item">
                    <img :src="require(`../../assets/images/portfolio/${item.image}`)" />
                    <div class="overlay">
                        <router-link :to="{ name: 'Project', params: { title: getProjectPageLink(item.title) } }" title="Project Details">
                            <div class="portfolio-item-content">
                                <h3>{{ item.title }}</h3>
                                <p>{{ item.description }}</p>
                                <div v-if="item.source !== '' " class="link-icons">
                                    <a :href="item.source" title="Source Code" target="_blank">
                                        <i class="fas fa-code fa-2x"></i>
                                    </a>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Arrow />
</section>
</template>

<script>
import data from "../../data/data.json";
import Arrow from "../components/Arrow.vue";
import {
    titleToPath
} from "../../utils/nameConversion.js";

export default {
    name: "portfolio",
    components: {
        Arrow,
    },
    data() {
        return {
            projects: data.portfolio.projects,
            heading: data.main.headings.portfolio,
            currentFilter: data.portfolio.defaultFilter,
        };
    },
    computed: {
        filteredProjects() {
            return this.projects.filter((project) =>
                project.filter.includes(this.currentFilter)
            );
        },
        filters() {
            const uniqueFilters = [...new Set(this.projects.flatMap((x) => x.filter))];
            return uniqueFilters.map((filter) => ({
                filter,
                title: filter,
            }));
        },
    },
    methods: {
        setFilter(event) {
            this.currentFilter = event.target.dataset.filter;
        },
        getProjectPageLink(title) {
            return titleToPath(title);
        },
    },
};
</script>

<style lang="scss"></style>
