<template>
<section class="dark-background">
    <div v-if="project" class="project-detail">
        <h1>{{ project.title }}</h1>
        <p v-if="project.detailedDescription" v-html="project.detailedDescription"></p>
        <a :href="project.link" target="_blank" class="project-link">View Project</a>
    </div>
    <div v-else>
        <p>Project not found.</p>
    </div>
</section>
</template>

<script>
import data from '../../data/data.json';
import {
    titleToPath
} from '../../utils/nameConversion.js';

const images = require.context('../../assets/images/portfolio', false, /\.jpg$|\.png$/);

export default {
    props: ['title'],
    data() {
        return {
            project: null,
        };
    },
    computed: {
        projectImageSrc() {
            return images(`./${this.project.image}`);
        },
    },
    created() {
        this.loadProject();
    },
    methods: {
        loadProject() {
            const routeTitle = titleToPath(decodeURIComponent(this.title));
            this.project = data.portfolio.projects.find(
                (project) => titleToPath(project.title) === routeTitle
            );
        },
    },
};
</script>

<style scoped>
.dark-background {
    min-height: 100vh;
    background-color: #222222;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.project-detail {
    padding: 2rem;
    text-align: center;
    background-color: #222222;
    color: #f0f0f0;
    max-width: 800px;
    /* Add a max-width to limit the content's width */
    margin: 0 auto;
    /* Center the content */
}

.project-detail h1 {
    font-size: 2.5rem;
    color: #ed2024;
    margin-bottom: 1.5rem;
}

.project-link {
    display: inline-block;
    background-color: #ed2024;
    color: #f0f0f0;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    margin-top: 1.5rem;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;
}

.project-link:hover {
    background-color: #d31115;
}
</style>
